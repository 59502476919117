import React, { useState } from 'react'
import { HiPlay } from 'react-icons/hi'

type Props = {}

export default function VideoItem({ }: Props) {
    const [mod,setModal]= useState(false)
    return (

       <>
        <div className="item">
            <div className="en-tete">
                <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
            </div>
            <div className="div">
                <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                <div className='play'>
                    <HiPlay className='icon' onClick={()=> setModal(!mod)}/>
                </div>
            </div>
          

        </div>
          {
            mod ? 
            <div className="if iframe">
                <div className="cancel" onClick={()=> setModal(!mod)}>X</div>
                <iframe  src="https://www.youtube.com/embed/MxTanKVhVj4" title="YouTube video player" frameBorder={"0"} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </div>:null
        }
       </>

    )
}