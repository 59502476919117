import React from 'react';
import { HiPlay } from 'react-icons/hi';
import VideoItem from '../composants/molecules/VideoItem';
import Navbar from '../composants/Navbar';
import { FaRegEnvelope } from 'react-icons/fa';
import { BsFillTelephoneInboundFill } from 'react-icons/bs'

type Props = {};

const Home = (props: Props) => {
    return <div className='container__fluid'>
        <header className='header'>
            <div className="mask"></div>
            <Navbar />
            <div className="right-text">
                <div className="en-tete">
                    <h1>Bienvenu sur <span>rubis<small>design</small></span></h1>
                    <hr className='separateur' />
                    <p>Nous sommes une agence qui offre conseils et marketings à 360 degrés</p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quaerat a quasi deleniti quas facere repellat minima, totam inventore asperiores quis laboriosam, quam explicabo doloremque incidunt vero eveniet reiciendis consequatur quidem!</p>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" className='wave'>
                <path fill="#000" fill-opacity="1" d="M0,96L80,117.3C160,139,320,181,480,213.3C640,245,800,267,960,256C1120,245,1280,203,1360,181.3L1440,160L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"></path>
            </svg>
        </header>
        <section className='us' id="us">
            <div className="left">
                <h1>Pourquoi  <small>travaillez avec</small> <span>nous </span> ?</h1>
                <hr className='separateur' />

                <p>Réussir un projet en alliant notre sens profond de l’organisation à notre créativité, est pour notre équipe un réel enjeu. Inscrire cette démarche dans une notion de temps, analyser l’ensemble des paramètres économiques, commerciaux, techniques et ergonomiques, saisir les meilleures opportunités pour le développement des projets voilà ce qui fait notre passion.</p>
            </div>

        </section>
        <section>    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#B71C1C" fill-opacity="1" d="M0,128L60,144C120,160,240,192,360,218.7C480,245,600,267,720,256C840,245,960,203,1080,160C1200,117,1320,75,1380,53.3L1440,32L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg></section>
        <section className='graf'>
            <div className="graf--items">
                <div className="item">
                    <h3>Du concept..</h3>
                    <p>Analyse, stratégie de marque, positionnement marketing conseil en communication global et opérationnelle, plan de développement...</p>
                </div>
                <div className="item">
                    <h3>..Au design</h3>
                    <p>Identité Visuel et logotype, création affiche et Charte graphique, interface web, Design multimédia, identité sonore...</p>
                </div>
                <div className="item">
                    <h3>Evénementiel..</h3>
                    <p>Spécialiste de l'événement, nous bénéficions aujourd'hui de belle référence thématique...</p>
                </div>
            </div>
        </section>
        <section>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#B71C1C" fill-opacity="1" d="M0,128L60,144C120,160,240,192,360,218.7C480,245,600,267,720,256C840,245,960,203,1080,160C1200,117,1320,75,1380,53.3L1440,32L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
            </svg>
        </section>
        <section className='galeries' id="galerie">
            <div className="container__fluid">
                <h5>Galerie images</h5>
                <h1>nos recentes réalisations</h1>
                <div className="galeries--images">
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://rubisdesign.co/images/work/20909011835df090e4505ed4.86154267.jpg" alt="image" />

                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                    <div className="item">
                        <div className="en-tete">
                            <img src="./img/logo.png" alt="rubisdesign logo" className='ic' />
                        </div>
                        <div className="div">
                            <img src="https://cdn.pixabay.com/photo/2016/11/29/11/34/shinto-shrine-gates-1869216_1280.jpg" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className='videos'>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#000" fill-opacity="1" d="M0,96L80,96C160,96,320,96,480,117.3C640,139,800,181,960,202.7C1120,224,1280,224,1360,224L1440,224L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
            </svg>
            <div className="container">
                <h5>Galerie vidéos</h5>
                <h1>nos recentes réalisations</h1>

            </div>
            <div className="container__fluid">
                <div className="galeries--videos">
                    <VideoItem />
                    <VideoItem />
                    <VideoItem />
                    <VideoItem />
                    <VideoItem />


                </div>
            </div>

        </section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#212121" fill-opacity="1" d="M0,64L60,90.7C120,117,240,171,360,197.3C480,224,600,224,720,218.7C840,213,960,203,1080,186.7C1200,171,1320,149,1380,138.7L1440,128L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
        </svg>
        <section className='partenaire'>
            <div className="container__fluid">
                <h5>ils nous accompagnent</h5>
                <h1>nos partenaires</h1>
                <div className="container">
                    <img src="https://cdn1.vectorstock.com/i/1000x1000/08/85/abstract-cool-modern-logo-vector-23740885.jpg" alt="" className='part' />
                    <img src="https://www.seekpng.com/png/detail/146-1461902_go-team-logo-cool-gaming-logos-png.png" alt="" className='part' />
                    <img src="https://99designs-blog.imgix.net/blog/wp-content/uploads/2018/07/attachment_36434417-e1531897536884.jpg?auto=format&q=60&fit=max&w=930" alt="" className='part' />
                    <img src="https://cdn.dribbble.com/users/36206/screenshots/14169025/media/ff76d11e352a9e8b0c7f35b298a76f06.jpg?compress=1&resize=400x300" alt="" className='part' />
                    <img src="https://img.freepik.com/free-vector/cool-monkey-logo-design-vector-illustrator_93835-258.jpg" alt="" className='part' />
                    <img src="https://c8.alamy.com/comp/2A94P01/simple-clear-cool-design-concept-for-your-business-logo-2A94P01.jpg" alt="" className='part' />
                    <img src="https://i.pinimg.com/originals/3f/3d/d9/3f3dd9219f7bb1c9617cf4f154b70383.jpg" alt="" className='part' />
                    <img src="https://pbs.twimg.com/profile_images/1249684108277501953/EjMkDaCN_400x400.jpg" alt="" className='part' />

                </div>
            </div>
        </section>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#B71C1C" fill-opacity="1" d="M0,64L60,90.7C120,117,240,171,360,197.3C480,224,600,224,720,218.7C840,213,960,203,1080,186.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
        <section className='contact'>
            <div className="container__fluid">
                <h1>Contact</h1>
                <p>
                    Avez-vous une question ou êtes-vous intéressé à collaborer avec nous ?
                </p>
                <p>N'hésitez pas à nous contacter en cliquant sur un de ces boutons.</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h3 onClick={() => document.location = 'mailto:contact@rubisdesign.co'}><FaRegEnvelope className='icon' /> contact@rubisdesign.co</h3>
                    <h3 onClick={() => document.location = 'tel:0990753266'}><BsFillTelephoneInboundFill className='icon' /> +243 99 075 32 66</h3>
                </div>
                <div className='logoFooter'>
                    <div>
                    <img src="./img/logo.png" alt="" />
                    </div>
</div>
            </div>
        </section>
    </div>
};

export default Home;
