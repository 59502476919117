import React from 'react';
import { Link } from 'react-router-dom';
import {HiOutlineMenuAlt3} from 'react-icons/hi'
type Props = {};

export default function Navbar({}: Props) {
  return <div className='navbar'>
       <div className="left">
       <div className="menu-icon">
       <Link to="" className='link'>
                 <HiOutlineMenuAlt3 className='icon'/>
            </Link>
       </div>
      <div className="logo">
          <img src="./img/logo.png"/>
      </div>
       </div>
      <div className="navbar--items">
            <Link to="" className='link'>
                 <div className="link-text">Accueil</div>
            </Link>
            <Link to="" className='link'>
                 <div className="link-text">le chef</div>
            </Link>
            <Link to="" className='link'>
                 <div className="link-text">cabane</div>
            </Link>
            <Link to="" className='link'>
                 <div className="link-text">recettes</div>
            </Link>
            
      </div>

  </div>;
}
