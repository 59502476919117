import React from 'react'
import { BrowserRouter,Routes, Route, Link } from "react-router-dom";
import Home from '../pages/Home';
const Navigation = ()=>{
    return (
        <BrowserRouter>
    <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
    )
}
export default Navigation;